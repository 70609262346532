.worldHistoryCredit {
  color: rgb(137, 186, 239);
}

.uSHistoryCredit {
  color: rgb(254, 106, 106);
}

.geographyCredit {
  color: rgb(36, 215, 36);
}

.governmentCredit {
  color: rgb(251, 70, 251);
}

.electiveCredit {
  color: violet;
}

.mandatoryCredit {
  color: rgb(105, 104, 104);
}

.lACredit {
  color: orange;
}

/* .allCoursesCredit {
  color: white;
} */

.light .allCoursesCredit {
  color: black;
  background-color: white;
}

.vaporWave .allCoursesCredit {
  color: white;
  background-color: #333;
}

.allCoursesCredit {
  /* background-color: currentColor; */
  text-align: center;
  font-size: 1.5rem;
  position: relative;
  border: thick double;
  margin-right: 1%;
  width: max-content;
  margin: 5%;
}

/* .allCoursesCredit, */
.worldHistoryCredit,
.uSHistoryCredit,
.geographyCredit,
.governmentCredit,
.electiveCredit,
.mandatoryCredit,
.lACredit,
.credit {
  text-align: center;
  font-size: 1.5rem;
  position: relative;
  border: thick double;
  margin-right: 1%;
  box-shadow: inset 0 0 0 1000px rgba(24, 27, 25, 0.394);
  width: max-content;
  margin: 5%;
}

.worldHistoryCredit:active,
.uSHistoryCredit:active,
.geographyCredit:active,
.governmentCredit:active,
.electiveCredit:active,
.mandatoryCredit:active,
.lACredit:active,
.credit:active {
  background-color: currentColor;
}
