*,
*::before,
*::after {
  box-sizing: border-box;
}

:root {
  --body-bg: hsl(0, 0%, 11%);
  --shadow-color: 310deg 19% 16%;
  --shadow-elevation-low:
    1px 0.7px 1.5px hsl(var(--shadow-color) / 0.4),
    2px 1.4px 3px -0.9px hsl(var(--shadow-color) / 0.48),
    5px 3.6px 7.5px -1.9px hsl(var(--shadow-color) / 0.56);
  --shadow-elevation-medium:
    1px 0.7px 1.5px hsl(var(--shadow-color) / 0.42),
    4.4px 3.1px 6.5px -0.6px hsl(var(--shadow-color) / 0.48),
    10.6px 7.6px 15.8px -1.3px hsl(var(--shadow-color) / 0.55),
    25px 18px 37.4px -1.9px hsl(var(--shadow-color) / 0.61);
  --shadow-elevation-high:
    1px 0.7px 1.5px hsl(var(--shadow-color) / 0.44),
    11.3px 8.1px 16.9px -0.3px hsl(var(--shadow-color) / 0.48),
    21.9px 15.8px 32.8px -0.6px hsl(var(--shadow-color) / 0.52),
    36.9px 26.6px 55.3px -0.9px hsl(var(--shadow-color) / 0.56),
    60.5px 43.6px 90.6px -1.3px hsl(var(--shadow-color) / 0.6),
    96.8px 69.7px 144.9px -1.6px hsl(var(--shadow-color) / 0.64),
    150px 107.9px 224.5px -1.9px hsl(var(--shadow-color) / 0.68);
}

.light {
  /* color: #414141; */
  /* text-shadow: 0px 1px white; */
  background-color: #F2F2F2;
}

html {
  color-scheme: dark light;
}

.wrapper {
  margin-inline: auto;
  padding-inline: 1rem;
}

body {
  background: var(--body-bg);
  font-family: "Open Sans", sans-serif;
  position: relative;
  padding-bottom: 2.5rem;
overflow-x: hidden;
padding-bottom: 4rem;
}

h3,
.card-body > .card-title {
    color: rgb(44, 214, 230);
}

.light .card-text {
  color: black;
}

.card-group {
min-height: fit-content;
}

.light h1 {
color:rgb(255,190,152);
}

.course-body,
.course-intro-text
 {
  font-size: large;
  font-family:Verdana, Geneva, Tahoma, sans-serif;
}

.vaporWave h1 {
color: rgb(44, 214, 230)
}

.light h2 {
    font-size:3rem;
  color: white;
text-align:start;
}

h1,
h2,
h3,
.resource-title,
.broken-pencil,
.stroke-n-shadow {
      font-family: "helvetica", cursive;
 -webkit-text-stroke: 1px black;
   text-shadow:
       3px 3px 0 #000,
     -1px -1px 0 #000,  
      1px -1px 0 #000,
      -1px 1px 0 #000,
       1px 1px 0 #000;
}

.vaporWave h2 {
  font-size:3rem;
color: magenta;
text-align:start;
}

h3 {
  text-align: center;
}

p {
font-size:large;
}

.card-title {
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 1000;
  font-size: 1.25rem;
}

.card-detail-title {
  writing-mode: vertical-rl;
  position: absolute;
  margin-left: 101%;
  font-size: xx-large;
}

.card-image 
{
  min-height: 7.5rem;
  max-height: 2rem;
  max-width: auto;
    position:relative;
  height: min-content;
  object-fit: cover;
}

.card-img-overlay .card-title {
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
}

.card-detail-image {
 object-fit: cover;
 height: 15rem; 
}

.xs-speech-card,
.speech-card,
.know-card
{
  height: max-content;
  font-size: larger;
position: relative;
top: 3%;
}

.speech-card {
top: 0%;
background-color: #FF512F;
color: FF512F;
}

.vaporWave .know-card a:link {
color: transparent;
}

.xs-speech-card {
  top: .5%;
left: 37%;
background-color: #259936;
color: FF512F;

    /* border: 2px solid rgb(44, 214, 230); */
}

.youtube-playlist
 {
  text-align: center;
  padding: 1%;
font-size: x-large;
}

.ai-input-component,
.vaporWave #Hard-for-Educators-Subsection,
.you-should-let-your-teaching-shine,
.course-body,
.youtube-playlist,
.card-img-overlay:hover .card-title {
  color: white;
  background: 
linear-gradient(
      rgba(0, 0, 0, 0.1),
      rgba(0, 0, 0, 0.3)
    ),
    radial-gradient(ellipse at top, #ec008c, transparent),
    radial-gradient(ellipse at bottom, #fc6767, transparent);
  opacity: 0.75;
  background-color: #af7fdd;
}

.light .∞-learning,
.card,
.worldHistoryCredit,
.uSHistoryCredit,
.geographyCredit,
.governmentCredit,
.electiveCredit,
.mandatoryCredit,
.lACredit,
.credit,
.sign-up-col
{
  box-shadow: var(--shadow-elevation-medium);
      -webkit-text-stroke: 0.03rem black;
backdrop-filter: blur(4px) saturate(150%);
    }

.worldHistoryCredit,
.uSHistoryCredit,
.geographyCredit,
.governmentCredit,
.electiveCredit,
.mandatoryCredit,
.lACredit,
.credit {
          margin-right: 1rem;
}

.credit-tag {
  background-color:white
}

.site-footer {
  padding: 15px 0;
    -webkit-text-stroke: .75px  rgb(0, 223, 244);
  font-size: larger;
  margin-top: 4rem;
  text-align: center;
    position: absolute;
}

.vaporWave .site-footer

{
color: rgb(224, 240, 5);
}

.page-enter {
  opacity: 0;
  transform: translateX(-100%);
}

.nav-link,
.vaporWave .nav-link,
.flick {
margin-left: 1.75rem;
  font-weight: bold;
  font-family: "Comfortaa";
  background-clip: text;
  -webkit-background-clip: text;
  color: rgb(44, 214, 230);
     -webkit-text-stroke: 0.004rem white;
            margin-top: 1rem;

}

@media all and (max-width: 1583px) {
.nav-link,
.vaporWave .nav-link {
margin-left: 1.75rem;
  font-weight: bold;
  font-family: "Comfortaa";
  background-clip: text;
  -webkit-background-clip: text;
  color: magenta;
       -webkit-text-stroke: 0.1rem white;

     /* -webkit-text-stroke: 0.04rem white; */
            margin-top: 1rem;

}
}

@media all and (max-width: 1583px) {
.nav-link,
.light .nav-link {
margin-left: 1.75rem;
  font-weight: bold;
  font-family: "Comfortaa";
  background-clip: text;
  -webkit-background-clip: text;
  /* color:  #FF512F;
     -webkit-text-stroke: 0.001rem rgb(44, 214, 230); */
          color: white;
     -webkit-text-stroke: 0.1rem rgb(41, 71, 41);
            margin-top: 1rem;

}
}

@media all and (max-width:  1173px) {
.nav-link,
.vaporWave .nav-link {
margin-left: 1.75rem;
  font-weight: bold;
  font-family: "Comfortaa";
  background-clip: text;
  -webkit-background-clip: text;
     /* color: white; */
          color: rgb(244, 200, 244);
     -webkit-text-stroke: 0.07rem black;
            margin-top: 1rem;

}
}

/* .nav-link .active {
  background-color: red;
  color: #af7fdd;
} */

/* li .navbar-nav {
  background-color:var(--body-bg);
width: max-content;
} */

.light .navbar-toggler,
.navbar-toggler {
  position: absolute;
    bottom: 10%;
    right: 2%;
    color: #259936;
        border-color: #69604D;
 /* background-clip: text;
  -webkit-background-clip: text;
     -webkit-text-stroke: 0.09rem rgb(255,190,152); */
  }

  .vaporWave .navbar-toggler { 
        border-color:magenta;
  }

.navbar-nav {
  position: absolute;
    bottom: 0%;
    right: 0%
}

.collapse .navbar-nav {
backdrop-filter:unset;
}

.show .navbar-nav {
  position:sticky;
  /* background-color:hsl(var(--shadow-color) / 0.08); */
backdrop-filter: blur(4px) saturate(150%);
width:max-content;
    border: 2px solid rgb(44, 214, 230);
    /* align-items: center; */
    z-index: 1;
  }

  .light .show .navbar-nav {
    border: 2px solid rgb(169, 209, 234);
    /* align-items: center; */
  }

  .navbar-text {
    /* position: sticky; */
    top:0rem;
    /* right:10rem; */
      background-clip: text;
  -webkit-background-clip: text;
     /* -webkit-text-stroke: 0.09rem rgb(255,190,152); */
  }

.page-enter-active {
  opacity: 1;
  transform: translateX(0%);
  transition: 300ms ease-out;
}

.page-exit {
  opacity: 1;
}

.title,
.title-xxl,
.title-xl,
.title-md-l,
.title-xs-sm
/* .btn, */
/* .nav-link */
  {
    
    color: rgb(255,190,152);
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  background-clip: text;
  -webkit-background-clip: text;
     -webkit-text-stroke: 0.09rem rgb(255,190,152);
font-size: 6rem;
text-align: center;
left: -45.5rem;
top:0rem;
position: absolute;
}

@media all and (max-width: 767px) {
  .light .nav-link 
  { 
      font-weight: bolder;
    font-family: "Comfortaa", cursive;
  background-clip: text;
  -webkit-background-clip: text;
         color: #69604D;
     -webkit-text-stroke: 0.05rem #f09819#69604D; 
     /* backdrop-filter: blur(4px) saturate(150%); */

    }
}
/* .btn, */
@media all and (min-width: 767px) {
.light .nav-link {
  font-weight: bolder;
    font-family: "Comfortaa", cursive;
  background-clip: text;
  -webkit-background-clip: text;
  /* color: #69604D;
     -webkit-text-stroke: 0.05rem #f09819; */
       color: #f09819;
     -webkit-text-stroke: 0.05rem #69604D;
     bottom: 0rem;
     /* left: 8rem; */
  }
}
  .header-banner {
height: 5rem;
filter:brightness(.96);
background-color: #00ffd5;
  }

.vaporwave-title
{

color: magenta;
     -webkit-text-stroke: 0.09rem magenta;
}

.vaporwave-title,
.title-xxl,
  .title-xl,
  .title-md-l,
  .title-xs-sm {
  position: absolute;
  top: -10%;
  }

.title-xxl {
  font-size:6.7rem;
  text-indent: 185%;
}

.title-xl {
  text-indent: 190.5%;
}

.title-md-l {
  text-indent: 160%;
}

.title-sm {
  text-indent: 7%;
font-size:5rem;
position: absolute;
  top: 0%;
}

.title-xs {
  /* text-indent: 0%; */
font-size:4.2rem;
position: absolute;
  top: 3%;
  /* left: 0% */
   /* background-size: 100%; */
}

@media all and (max-width: 455px) {.title-xs { 
  /* text-indent: 3%; */
    left: 0%;
font-size:3.8rem;
 }
}

@media all and (max-width: 320px) {.title-xs { 
  /* text-indent: 3%; */
    left: 0%;
font-size:3.2rem;
 }
}

.page-exit-active {
  opacity: 0;
  transition: 300ms ease-in;
}

#heart {
  transition: 0.1s;
  border: none;
  cursor: pointer;
  filter: grayscale(1);
}

#heart:hover {
  font-size: 2rem;
  transform: scale(1.23);
  filter: grayscale(0);
}

.flick {
position: relative;
  top: 2rem;
left: 2.3rem;
}

#button,
#bulbBanner,
.darkBulb {
  position: absolute;
    font-size: x-large;
z-index: 10;
  top: 3.25rem;
  left: .5rem;
    -webkit-text-stroke: 1px rgb(218, 236, 17);
  border-radius: 20px;
}

.darkBulb {
color: rgb(44, 214, 230);
}

.light #button {
  position: absolute;
  background-color: transparent;
  border-color: transparent;
    border-radius: 30px;
    z-index: 10;
}

.light #button,
.bulb-shine-yellow,
.bulb-shine-orange,
.bulb-shine-red
 {
  position: absolute;
  top: 6.3rem;
  left: -2.75rem;
}

.bulb-shine-yellow {
  position: relative;
  top: -.3rem;
  left: 3.8rem;
  /* font-size: 5rem; */
  color: yellow;
    color: linear-gradient(45deg, #FF512F 0%, #F09819  51%, #FF512F  100%);
/* -webkit-text-stroke: 1px transparent */
z-index: -1;
}

.bulb-shine-orange {
 position: relative;
  top: -.3rem;
  left: 2.5rem;
  color: orange;
    color: linear-gradient(45deg, #FF512F 0%, #F09819  51%, #FF512F  100%);
/* -webkit-text-stroke: 1px transparent */
z-index: -1;
}

.bulb-shine-red {
 position: relative;
  top: -.3rem;
  left: 1.2rem;
  color: red;
    color: linear-gradient(45deg, #FF512F 0%, #F09819  51%, #FF512F  100%);
/* -webkit-text-stroke: 1px transparent */
z-index: -2;
}

.slow-spin {
  -webkit-animation: fa-spin 8s infinite linear;
  animation: fa-spin 8s infinite linear;
}

.slower-and-backwards-spin {
  -webkit-animation: fa-spin 0s infinite linear;
  animation: fa-spin 9s infinite linear;
    -webkit-animation-direction:reverse;
     -moz-animation-direction:reverse;
          animation-direction:reverse;
}

.button-85 {
  /* padding: 0.6em 2em; */
    padding: .6em 3.8em;
  border: none;
  outline: none;
  color: rgb(255, 255, 255);
  background: #111;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 15px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

/* .sign-up-col, */
.button-85:before {
  content: "";
  background: linear-gradient(
    45deg,
    #ff0000,
    #ff7300,
    #fffb00,
    #48ff00,
    #00ffd5,
    #002bff,
    #7a00ff,
    #ff00c8,
    #ff0000
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  -webkit-filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing-button-85 20s linear infinite;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}

@keyframes glowing-button-85 {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

.button-85:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #222;
  left: 0;
  top: 0;
  border-radius: 10px;
}

#free-course {
  position: static;
  margin-right: 200rem;
  /* margin-top: 1rem; */
  /* margin-bottom: 1rem; */
  padding: 5px;
   margin-bottom: 1%;
}

#social-bar {
  background-size: 2100px;
  background-image: url("https://images.surferseo.art/72645927-376a-41df-90b4-183722dca33b.jpeg");
  background-position: 90% 21%;
}

.displaylist {
     width: 100%;
  display: flex;
      gap: 1rem;
    /* background-color: #a91db0; */
}

/* .card-body,
.card-group, */
/* .card-text, */
/* button, */
/* .dispaly-card-group, */
.displaylist-child 
{
  flex: 1;
  flex-basis: 1;
  /* background-color: #00ffd5; */
font-size: calc(1vw + 1vh);
background-color: unset;
}

/* .card-image, */
.dispaly-card-image {
  position:relative;
  border: 4px solid black;
  height: min-content;
  object-fit: cover;
}

.vaporWave .dispaly-card-image {
    border: 4px solid rgb(44, 214, 230);
}
.dispaly-card-intro {
  max-height: 1rem;
font-size: small;
color: #ec008c;
}

.dispaly-card-group {
/* padding-bottom: .5rem; */
}

#TeachersLounge {
     /* height: 90%;
     background-color: aqua;
         width: 100%; */
  /* display: flex;
      gap: 1rem;
    background-color: #ae6135; */
}

@media all and (max-width: 385px) { #TeachersLounge  { 
  /* position: absolute; */
padding-top: 7rem;
 }
}

.diaganol {
  --diaganol-color:var(--body-bg)
  padding: 1rem;
  --skew-angle: -5deg;
  position: relative;
  isolation: isolate;
  margin-top: 5rem;
  background: transparent;
}

.diaganol::before {
  content: "";
  margin-top: 0.001rem;
  padding-top: 1rem;
  background: linear-gradient(
    25deg,
    rgb(54, 87, 153),
    rgb(108, 49, 108),
    rgb(129, 39, 39)
    
  );
  position: absolute;
  inset: 0;
  transform: skewY(var(--skew-angle));
  z-index: -1;

  filter: blur(5px);
  -webkit-filter: blur(5px);
}

@keyframes glowing-dream {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

.warm-up-title {
  max-height: 2rem;
    margin-top: 1.75rem;
  /* margin-bottom: 2rem; */
    padding-top: 1rem;
  padding-bottom: 3rem;
    --skew-angle: 0deg;

}

#dream {
  color: rgb(47, 209, 87);
    -webkit-text-stroke: 0.02rem #111ac8;

}

.sign-up-col,
.course-links-container,
.comments 
{
  z-index: 10;
    background:
    linear-gradient(white, rgba(0, 0, 0, 0.3));  
    padding-top:1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    border: 2px solid black;
}

.course-links-container {
  position: inline;
  padding-bottom: 0;
  vertical-align: middle;
  /* line-height: 1.5rem; */
/* padding: fit-content; */
}

/* .spikes {
  --spike-color: var(--body-bg);
position: relative;
height: 100px
} */

/* .spikes::before,
.spikes::after { 
content: '';
position: absolute;
background-attachment:fixed;
width: 100%;
height: 50 px;
background: var(--spike-color);
-webkkit-mask-image: url('app/assets/img/triangle.svg');
mask-image: url('app/assets/img/triangle.svg');
 } */

 /* .spikes::before {
top: 0;
 }

  .spikes::after {
  bottom: 0;
  transform: rotate(.5turn);
 } */

.teach-league-logo {
   position: absolute;
   top: 12%;
  opacity: 0.85; 
  -webkit-mask-image: url('./app/assets/img/TLLogo.svg');
  mask-image: url('./app/assets/img/TLLogo.svg');
      /* width: 10%; */
      /* height: 115%; */
      height: auto;
/* backdrop-filter: blur(4px) saturate(150%); */
}

.vaporWave .teach-league-logo {
  filter:brightness(65%)  saturate(400%);
}

.vaporWave .resources-list {
  position: relative;
  padding-bottom: 2rem;
     background: 
  url("./app/assets/img/Blue-Aesthetic-PNG.png")
      no-repeat, linear-gradient(
      rgba(0, 0, 0, 0.1),
      rgba(0, 0, 0, 0.3)
    )
    ,
    radial-gradient(ellipse at top, #ec008c, transparent);
  background-position: -3rem -5rem;
  border: 2px solid rgb(44, 214, 230);
  color: magenta;
}

.light .resources-list {
  padding-bottom: 2rem;
   box-shadow: var(--shadow-elevation-low);
      -webkit-text-stroke: 0.03rem black;

     background: 
  url("./app/assets/img/kisspng-green-paint-brushes-watercolor-painting-light-7-light-green-watercolor-brush-stroke-png-transpa-5cfe874fbf4a88.1910132315601846557835.png")
      no-repeat, linear-gradient(
      rgba(0, 0, 0, 0.1),
      rgba(0, 0, 0, 0.3)
    );
  background-position: 0rem 0rem;
  background-size: 85%;

  border: 2px solid black;
}

.resource-img {
position:relative;
/* margin-top: 5%; */
/* margin-left: 2rem; */
z-index: 10;
border: 2px solid black;
}

.resource-img  {
  position:relative;
  /* top: 15%; */
  border: 4px solid black;
  /* bottom: 50%; */
  /* max-width: 100%; */
  /* height: auto; */
  /* height: min-content; */
  object-fit: contain;
}

.light .resource-img  {
  /* position: absolute; */
  border: 4px solid black;
  /* right: 5%; */
}

.vaporWave .resource-img  { 
  border: 4px solid rgb(44, 214, 230);
}

.resource-title
 {
/* text-align:center;   */
font-weight: 1000;
  font-size: 2.25rem;
  /* margin-left: 15%; */
  /* background-color: transparent; */
}

.take {
  /* position:sticky; */
  top: 0%;
  left: 25%;
  height: fit-content;
}

.extra-take {
    border-top: 2px solid rgb(255,190,152);
}

.vaporWave .extra-take {
    border-top: 2px solid rgb(44, 214, 230);
}

.subheader-title {
color:unset
}

.light .subheading {
/* margin-left: unset; */
  /* color: #69604D; */
}
.subheading {
font-size: x-large;
font-family:Verdana, Geneva, Tahoma, sans-serif;
/* color: #9600ff; */
}

.CardGroup {
  border: 2px solid rgb(44, 214, 230);
}

.shine {
  position: absolute;
top: 10.1rem;
left: 2rem;
color: yellow;
      -webkit-text-stroke: 0.03rem black;
margin-left: 1.75rem;
  font-family: Arial, Helvetica, sans-serif;

}

.load-courses-buttons {
position: relative;
left: 30%;
}

.build-community {
  /* font-size: calc(3vw + 7vh); */
    /* color: rgb(255,190,152); */
  font-size: 5rem;
  font-size: calc(5vw);
  font-family:fantasy;
  /* height:5rem; */
    /* background: -webkit-linear-gradient(#09203f, #958653); */
    background: rgb(2,0,36);
/* background-position: 25% 75%; */
background: linear-gradient(170deg, rgba(2,0,36,1) 0%, rgba(26,149,146,1) 35%, rgba(0,212,255,1) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  /* color: rgb(34, 6, 61); */
  margin-bottom: 0%;
  position: absolute;
  left: 24%;
  top: 16%;
}

 .copy-button {
    all: initial;
  /* box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19); */

/* margin-left: 1.75rem; */
  /* font-weight: bold; */
  /* font-family: "Comfortaa"; */
  /* background-clip: text; */
  /* -webkit-background-clip: text; */
  /* color: rgb(44, 214, 230); */
     /* -webkit-text-stroke: 0.001rem rgb(44, 214, 230); */
/* background-color: #111ac8;
  color: blue; */
  /* border: 2px solid #04AA6D; */
  /* gap: 12rem; */
}

/* button,
.btn {
  background-color:blue;
  color: blue
} */

/* .btn-danger
{
  color: #fc6767;
  background-color: blue;
} */

.register-message {
  color: black;
  text-align: center;
}

.register-envelope {
  color:rgb(51,102,204);
  text-align: center;
}

.Not-Just-You {
    position: absolute;
  top: 5%;
text-align: center; 
color: rgb(233, 132, 182);
font-size: 3rem;
}

.broken-pencil {
        font-family: "helvetica", cursive;
  min-height: 25rem;
  width: 50%;
background-size: 800px;
  background-image:   url("./app/assets/img/broken-pencil.png");
  background-repeat: no-repeat;
  background-position: 45% 50%;
  /* animation: fade-in linear;
  animation-timeline: view(); */
}

.broken-pencil-section{
   /* height: 100svh; */
/* background-color: #af7fdd; */
/* color: #af7fdd; */
}

.Hard-for-Educators {
  position: inline;
  /* top: 12%; */
font-size: 4rem;
text-align: center;
}

@media all and (max-width: 385px) { .Hard-for-Educators  { 
  /* position: absolute; */
padding-top: 5rem;
 }
}


/* @media all and (max-width: 385px) {#Hard-for-Educators-Subsection { 

 }
} */

#Hard-for-Educators-Subsection {
left:5%;
border-radius: 5%;
font-size: 1.8rem;
text-align: center;
/* margin-left: 5rem;
margin-right: 5rem;
padding-left: 5rem; */
font-weight: 700;}

.youtube-playlist,
.hero-button {
  position: absolute;
/* right: 45%; */
/* top: 10%; */
  /* margin: 10px; */
  padding: 15px 30px;
  text-align: center;
  /* text-transform: uppercase; */
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  border-radius: 10px;
  display: block;
  border: 0px;
  font-weight: 700;
  box-shadow: 0px 0px 14px -7px #f09819;
  background-image: linear-gradient(45deg, #FF512F 0%, #F09819  51%, #FF512F  100%);
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.vaporWave .youtube-playlist,
.vaporWave .hero-button {
 color: white;
   /* box-shadow: 0px 0px 14px -7px #c9c0b3; */
  background-image: 
linear-gradient(
  45deg, #ec008c 0%, #fc6767  51%, transparent  100%
      /* rgba(0, 0, 0, 0.1),
      rgba(0, 0, 0, 0.3)
    )
    ,
    radial-gradient(ellipse at top, #ec008c, transparent),
    radial-gradient(ellipse at bottom, #fc6767, transparent */
    );
  opacity: 0.75;
  background-color: #af7fdd;
}

.youtube-playlist:hover,
.hero-button:hover {
  background-position: right center;
  /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

.youtube-playlist:active,
.hero-button:active {
  transform: scale(0.95);
}

 #intro-info {
      position: relative; 
     /* height: 100svh; */
     /* background-color: #ec008c; */
     /* z-index: -100; */
     padding-top: 10%;
     margin-bottom: 6%;
     padding-bottom: 0%;
     padding-left: 5%;
        -webkit-text-stroke: .5px rgb(242, 59, 190);
        color: black;
        font-family:'Courier New', Courier, monospace;
          border-bottom: 1px solid magenta;

}

 #intro-info::before {
      content: "";
            position: absolute;
            background-size: cover;
              top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
      opacity: 0.5;
    background-size: 700px;
  background-image:   url("./app/assets/img/fist-with-pencil.png");
    background-repeat: no-repeat;
    background-position: 15% 95%;
    z-index: -10;
 }
.vaporWave #intro-info {
       color:rgb(44, 214, 230);

}

/* .intro-info-bg {
    opacity: .9;
    background-size: 700px;
  background-image:   url("./app/assets/img/fist-with-pencil.png");
    background-repeat: no-repeat;
    background-position: 15% 95%;
} */

.intro-button {
top: 45%;
}

#enough {
  margin-top: 10%;
        font-family: "helvetica", cursive;
color: rgb(255,190,152);
  /* min-height: 19rem; */
  /* width: 35rem; */
    background-size: 1210px;
  background-image:   url("./app/assets/img/crumpled_paper.png");
    background-repeat: no-repeat;
  text-align: center;
  font-size: 4rem;
  background-position: 50% 50%;
       padding-bottom: 10%;

     /* height: 100svh; */
/* background-color: #fc6767; */
}

.crumple {
  position:static;
  height: 10rem;
  font-weight: bold;
font-size: 3.7rem;
  color:black;
        /* -webkit-text-stroke: 0.03rem white; */

   color: white;
   -webkit-text-stroke: 1px black;
   text-shadow:
       3px 3px 0 #000,
     -1px -1px 0 #000,  
      1px -1px 0 #000,
      -1px 1px 0 #000,
       1px 1px 0 #000;
}

@media all and (max-width: 455px) {.crumple { 
font-size:2.5rem;
 }
}

.flick-the-lights { 
color: rgb(255,190,152);
}

.vaporWave .flick-the-lights { 
color: #00ffd5;
}

.xs-lounge-button {
top: 10%;
right: 12%;
}

.xs-get-schooled-button {
top: 8%;
right: 12%;
}

.sm-md-lounge-button {
top: 8%;
left: 8%
}

.sm-md-get-schooled-button {
top: 8%;
right: 12%;
}

.lg-lounge-button {
top: 10%;
left: 20%
}

.lg-get-schooled-button {
top: 10%;
right: 20%;
}

.xl-xxl-lounge-button {
top: 12%;
left: 33%
}

.xl-xxl-get-schooled-button {
  /* position: absolute;
  width: 4rem; */
  /* padding: 10px 24px; */
  /* border-radius: 12px; */
top: 12%;
/* left: 60%; */
right: 29%;
}

#BreadCrumb {
  /* font-size:.5rem; */
  position: relative;
  top: 3.15rem;;
  left: 2rem;
  padding-bottom: 10%;
width: 95%;  
}

.light .∞-learning {
  content: "";
    position: absolute;
  bottom:4rem;
  left: 3rem;
  text-align: left;
      filter:brightness(.96);

}

.vaporWave .∞-learning {
    content: "";
  position: absolute;
  /* width: 100%; */
  /* top:4rem; */
  left: 3rem;
  bottom: 4rem;
  text-align: left;
  color: rgb(150, 142, 150);
  /* background-color:unset; */
/* backdrop-filter: blur(4px) saturate(150%); */
}


/* .sharetoclassroom {
  position: absolute;
  top: 210rem;
  right: 10%;
} */

.gclassroom-padding {
  height: 4.5rem;
}

.row-content {

}

.dt-and-archive-label {
  margin-top: 5rem;
  text-align: center;
}

.dt-and-archive-label-subheadings {
}

.you-should-let-your-teaching-shine {
max-width: fit-content;
margin-left: 25%;
font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.login-btn,
.vaporWave .login-btn {
  position: absolute;
  bottom:4rem;
  left: 95rem;
  /* color: white; */
  /* border: 1px solid white; */
  /* color: white; 
  border: 1px solid white; */
  background-color: #00ffd5;
  color: magenta;
}

/* @media all and (max-width: 1583px) {
.login-btn,
.vaporWave .login-btn {
    position: absolute;
  bottom:-4rem;
  left: 22rem;
}
} */

@media all and (max-width: 1583px) {
.login-btn,
.vaporWave .login-btn {
    position: absolute;
  top:0rem;
  left: 0rem;
}
}

/* .have-an-account-login-btn {
position: inline;
left:10rem;
} */

/* .top-of-fold-sign-up-btn {
position:absolute;
left: 5;
color: #00ffd5;
} */

.sign-up-btn {
  position: relative;
right: 50%;
/* left: 50%; */
margin-top: 10%
}

.sign-up-col {
position: relative;
align-items: center;
padding: 15px;
/* margin-bottom: 5%; */
/* margin-left: 18%; */
 height: fit-content;
 margin-bottom: 3%;
}

.avatar {
  position: absolute;
  bottom:25%;
  right: 0%;
  width: 3.5rem;
  height: auto;
    border-radius: 30px;
  border: 2px solid black;
}

.vaporWave .avatar {   border: 2px solid magenta
   /* #00ffd5 */
   ;
}

.ai-input-component {
  padding: 1.5%;
  margin-left: 12%;
  margin-right: 12%;
  border: 2px solid rgb(147, 103, 103);
}

.ai-input-field {
  margin-bottom: 2rem;
}

.ai-submit-btn {
  margin-top: 2%;
margin-left: 28%;
}

.ai-prompt-boilerplate {
margin-top: 2rem;
margin: 1rem;
}

.ai-textarea {
  margin-bottom: 1rem;
margin-left: 14%;
width: 75%;
}

.ai-response {
margin-right: 5rem;
}

/* .social-media-links {
text-align: center;
}; */

/* .btn-social-icon {
display: block;
} */

/* .sign-up-btn {
text-align: center;  
border: 7px solid red;  
width: 300px;  
height: 200px;  
padding-top: 100px; 
} */