.vaporWave .card-body {
  background-size: cover;
  background-position: 95% 80%;
  background-clip: content-box;
  opacity: 0.75;
  background: linear-gradient(
    25deg,
    rgb(54, 87, 153),
    rgb(108, 49, 108),
    rgb(129, 39, 39)
  );
}

.vaporWave {
  background-color: #333;
  opacity: 2;
  color: white;
}

.vaporWave .credit-tag {
  background-color: #333;
}

.vaporWave a:link:not(.title) {
  color: white;
}

.vaporWave .card-header {
  background-color: black;
}

.vaporWave #social-bar {
  background-image: url("https://images.surferseo.art/72645927-376a-41df-90b4-183722dca33b.jpeg");
}

.light .card-header {
  background-color: #3046c5;
  color: white;
}

.vaporWave .site-footer {
  background-blend-mode: hard-light;
  filter: invert(8%);
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  background: url(app/assets/img/wp5004292-vaporwave-aesthetic-1920x1080-wallpapers.jpg)
      /* url(app/img/Color-Explosion.png) */ no-repeat,
    linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3)),
    radial-gradient(ellipse at top, #ec008c, transparent),
    radial-gradient(ellipse at bottom, #fc6767, transparent);
  background-position: 50% 25%;
  /* background-size: 100%; */
  width: 100%;
  filter:contrast(.96);
}

.light .site-footer {
  border: 1px solid rgb(47, 144, 177);
  background: url("https://png.pngtree.com/background/20210715/original/pngtree-green-simple-plant-grid-background-picture-image_1282533.jpg")
      no-repeat,
    linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3)),
    radial-gradient(ellipse at center, red, transparent),
    radial-gradient(ellipse at top, orange, transparent),
    radial-gradient(ellipse at bottom, yellow, transparent),
    linear-gradient(red, gold);
  background-position: 50% 100%;
  width: 100%;
}

.vaporwave-logo-md-xxl {
  position: absolute;
  mask-image: url("./app/assets/img/TLLogo.svg");
  width: 10%;
  height: 115%;
  margin-right: 7rem;
  background-attachment: local;
  stroke-width: 5x #af1ad4;
}

.vaporwave-logo-xs-sm {
  position: absolute;
  -webkit-mask-image: url("./app/assets/img/TLLogo.svg");
  mask-image: url("./app/assets/img/TLLogo.svg");
  width: 10%;
  height: 70%;
  background-color: #a4b82d;
}

#bulbBanner {
  color: #000;
  position: fixed;
  left: 0.09rem;
  top: 6.5rem;
}

.vaporWave .navbar-dark {
  background-color: #901cad;
  height: 5rem;
  padding-top: 4rem;
  padding-bottom: 4rem;
  min-width: none;
  border-top: 1px solid #000;
  border-bottom: 1px solid rgb(0, 0, 0);
  background: url("app/assets/img/statue-with-halo.jpg") no-repeat,
    linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3)),
    radial-gradient(ellipse at top, #ec008c, transparent),
    radial-gradient(ellipse at bottom, #fc6767, transparent);
  background-position: -2.5% 45%;
  background-size: 48%;
  /* opacity: .75; */
filter:brightness(.96);

}

@media all and (max-width: 500px) {
  .vaporWave .navbar-dark {
    background-size: 101%;
    background-position: 2% 45%;
    background-blend-mode: color-dodge;
    /* filter: invert(8%); */
    /* background-color:color-mix(in srgb-linear, transparent 45%, black 55%); */
    /* backdrop-filter: blur(4px) saturate(150%); */
  }
}

@media all and (max-width: 1173px) {
  .vaporWave .navbar-dark {
    background-size: 100%;
    background-position: 0% 60%;
    background-blend-mode: color-dodge;
    filter: invert(0.01);
    /* filter:brightness(90%); */
    /* background-color:color-mix(in srgb-linear, transparent 45%, black 55%); */
    /* backdrop-filter: blur(4px) saturate(150%); */
    /* opacity: 1; */
  }
}

.light .navbar-dark {
  background-color: #901cad;
  height: 5rem;
  padding-top: 4rem;
  padding-bottom: 4rem;
  min-width: none;
  border-top: 1px solid #000;
  border-bottom: 1px solid rgb(0, 0, 0);
  background: url("https://png.pngtree.com/background/20210715/original/pngtree-green-simple-plant-grid-background-picture-image_1282533.jpg")
    no-repeat;
  background-position: 100% 2.5%;
  width: auto;
  min-height: 2rem;
  background-size: 100%;
}

.vaporWave .diaganol {
  background-color: #000;
  color: #a4b82d;
}

.vaporWave .freeCourseCardTitle {
  background: linear-gradient(
    25deg,
    rgb(54, 87, 153),
    rgb(108, 49, 108),
    rgb(129, 39, 39)
  );
}

.vaporWave .comments,
.comments button {
  color: #000;
}
